import React from 'react';
import '../styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return (
    <header className="header">
      <div className="profile-pic">
        <h2>
          <img src={require('../assets/imgs/github-pfp.jpg')} alt="Profile" /> Nath
        </h2>
      </div>
      <nav>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#skills">Skills</a></li>
          <li>
            <a href="https://github.com/nath-du" target="_blank" rel="noopener noreferrer">
              GitHub <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="2xs" />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;