import React from 'react';
import '../styles/SkillsCard.css';

const SkillsCard = ({ level, title }) => {
  return (
    <div className="skills-card">
      <img src={`https://skillicons.dev/icons?i=${title}`} alt={title} />
      <div className="skills-info">
        <h4>{level}%</h4>
        <h3>{title}</h3>
      </div>
    </div>
  );
};

export default SkillsCard;