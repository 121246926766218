import React from 'react';
import '../styles/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <hr className="footer-separator" />
      <div className="footer-content">
        <div className="footer-socials">
          <p>Contact me</p>
          <p>
            <a href="mailto:nathinbox@proton.me" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faEnvelope} /> nathinbox@proton.me
            </a>
          </p>
          <p>
            <a href="https://discord.com/channels/@me/716724900617912481" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} /> .sshnath
            </a>
          </p>
        </div>
        <div className="footer-info">
          <p>Created with React</p>
          <p>Developed by Nath</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
