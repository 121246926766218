import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import ProjectCard from './components/ProjectCard';
import SkillsCard from './components/SkillsCard';
import './styles/App.css';


const App = () => {
  const projects = [
    { title: 'pnrtscr', description: 'Mirror of pnrtscr.com rewritten based on a YouTube video.', stars: 0, language: 'JavaScript', url: 'https://github.com/nath-du/lightshot-jumpscare' },
    { title: 'Karol Bot', description: 'Discord bot for moderation, entertainment, and utility.', stars: 1, language: 'Python', url: 'https://karolbot.vercel.app/' },
    { title: 'Credential Generator', description: 'Local generator for random passwords and usernames.', stars: 0, language: 'Javascript', url: 'https://github.com/nath-du/credential-generator' },
    { title: 'JavaEdit Checker', description: 'Tool to detect SHA256 of suspicious versions and compare them with a predefined list.', stars: 0, language: 'Python', url: 'https://github.com/nath-du/JavaEditChecker' },
    { title: 'portfolio', description: 'My personal portfolio made in react.js', stars: 0, language: 'React', url: 'https://github.com/nath-du/Portfolio' }
  ];


  const skill = [
    { level: 70, title: 'html' },
    { level: 70, title: 'css' },
    { level: 40, title: 'react' },
    { level: 20, title: 'javascript' },
    { level: 80, title: 'python' },
    { level: 5, title: 'typescript' },
    { level: 10, title: 'c' },
    { level: 'learning...', title: 'cpp' }
  ];


  return (
    <div className="app">
      <Header />
      <main>
        <section className="intro">
          <h1>I'm Nath</h1>
          <p>I’m a junior developer with a passion for programming, mainly in <a className='bold'>C</a> and <a className='bold'>Python</a>. I’m 16 years old and currently studying <a>Computer Science</a>, but my interest in this field started when I was very young.</p>
          <p>In my free time, I enjoy working on random or practical projects. When I’m not coding, I like to listen to music, chat with friends on Discord, and play video games, especially those developed by indie creators.</p>
        </section>
        <section className="projects">
          <h2>My Projects</h2>
          <div className="project-grid">
            {projects.map((project, index) => (
              <ProjectCard key={index} {...project} />
            ))}
          </div>
        </section>

        <section id='skills' className="skills">
          <h2>Skills</h2>
          <div className="skills-grid">
            {skill.map((skill, index) => (
              <SkillsCard key={index} {...skill} />
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default App;
