import React from 'react';
import '../styles/ProjectCard.css';

const ProjectCard = ({ title, description, stars, language, url }) => {
  return (
    <a href={url} className="project-card" target="_blank" rel="noopener noreferrer">
      <h3>{title}</h3>
      <p>{description}</p>
      <div className="project-info">
        <span>⭐ {stars}</span>
        <span>{language}</span>
      </div>
    </a>
  );
};

export default ProjectCard;
